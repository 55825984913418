import {
  audioActions,
  audioSelectors,
} from "@/services/store/slices/AudioStore";
import { useRootDispatch, useRootSelector } from "@/services/store/useStore";
import { IconButton } from "@chakra-ui/react";
import * as React from "react";
import { MdVolumeMute, MdVolumeOff } from "react-icons/md";

export function MuteButton() {
  const isMuted = useRootSelector(audioSelectors.muted);
  const dispatch = useRootDispatch();

  const toggleMute = React.useCallback(
    (shouldMute?: boolean) => {
      const updatedValue =
        typeof shouldMute === "boolean" ? shouldMute : !isMuted;

      dispatch(audioActions.toggleMute(shouldMute));

      return updatedValue;
    },
    [dispatch, isMuted]
  );

  const handleMute: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    toggleMute();
  };

  return (
    <IconButton
      icon={isMuted ? <MdVolumeOff /> : <MdVolumeMute />}
      aria-label="Mute"
      onClick={handleMute}
      cursor="pointer"
      position="fixed"
      bottom="5%"
      right="5%"
      rounded="full"
      zIndex="docked"
      size="md"
    />
  );
}
