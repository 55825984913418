import { Box, BoxProps } from "@chakra-ui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import * as React from "react";

import { APP_NAME } from "@/api/environment";

/**
 * Properties for layouts.
 * All other layouts should extend this one.
 */
export type BaseLayoutProps = {
  children: React.ReactNode;

  /** Meta Properties to add to {@link Head}, opengraph, twitter, etc. */
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;

  /** Styling Props */
  mainProps?: BoxProps;
};

/**
 * BaseLayout
 *
 * The default/base layout for the site.
 * Can be overriden by adding a `getLayout` function to the page.
 *
 * See https://nextjs.org/docs/basic-features/layouts
 *
 * @param {BaseLayoutProps} props
 */
export function BaseLayout({
  title = APP_NAME,
  description = `${APP_NAME} is a web application that aims to support you in doing something that many people find difficult -- really seeing yourself and others within a container of unconditional love.`,
  image = "https://gaze.app/images/logo.png",
  keywords = "",
  mainProps,
  children,
}: BaseLayoutProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta
          property="og:url"
          content={`https://gaze.app/${router.pathname}`}
        />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Head>

      <Box as="main" {...mainProps}>
        {children}
      </Box>
    </>
  );
}
