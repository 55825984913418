import { Icon, IconProps } from "@chakra-ui/react";

export function HeartEye(props: IconProps) {
  return (
    <Icon {...props} height="196" width="196" viewBox="0 0 196 196">
      <path
        d="M152.523 85.516c-27.916-30.176-75.01-32.009-105.187-4.093a74.53 74.53 0 0 0-4.093 4.093l-2.123 2.248 2.123 2.247a74.752 74.752 0 0 0 54.638 23.88 74.75 74.75 0 0 0 54.642-23.886l2.123-2.244Zm-74.925 18.689a69.258 69.258 0 0 1-27.394-16.448A69.256 69.256 0 0 1 77.598 71.31c-7.8 9.578-7.8 23.317 0 32.895Zm20.283 3.152c-10.819-.012-19.587-8.78-19.6-19.6 0-10.825 8.775-19.6 19.6-19.6 10.825 0 19.6 8.775 19.6 19.6 0 10.825-8.775 19.6-19.6 19.6Zm20.283-3.152c7.801-9.578 7.801-23.317 0-32.895a69.242 69.242 0 0 1 27.394 16.447 69.244 69.244 0 0 1-27.394 16.448Z"
        fill="#1C1C1C"
      />
      <path
        d="M97.881 74.691c-7.216 0-13.066 5.85-13.066 13.066 0 7.217 5.85 13.067 13.066 13.067 7.217 0 13.067-5.85 13.067-13.067 0-7.216-5.85-13.066-13.067-13.066Zm0 19.6a6.533 6.533 0 1 1 0-13.067 6.533 6.533 0 0 1 0 13.067Z"
        fill="#1C1C1C"
      />
      <path
        d="M159.475 49.937a39.448 39.448 0 0 0-2.53-2.529c-16.535-15.072-41.57-15.899-59.064-1.951-17.495-13.948-42.533-13.117-59.064 1.96-16.031 14.637-17.161 39.497-2.525 55.528a39.037 39.037 0 0 0 2.525 2.524l59.064 55.367 59.065-55.376c16.03-14.634 17.162-39.492 2.529-55.523Zm-4.777 48.538a32.697 32.697 0 0 1-2.218 2.219l-54.599 51.185-54.599-51.185c-13.398-12.171-14.394-32.899-2.223-46.297a32.83 32.83 0 0 1 2.223-2.223h.003c14.83-13.535 37.532-13.535 52.362 0l2.234 2.097 2.235-2.097c14.832-13.532 37.532-13.532 52.364 0 13.398 12.173 14.392 32.903 2.218 46.301Z"
        fill="#1C1C1C"
      />
    </Icon>
  );
}
