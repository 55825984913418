import * as React from "react";
import { Text, VStack, Heading } from "@chakra-ui/react";

import { APP_NAME } from "@/api/environment";
import {
  QuestionnaireStep,
  QuestionnaireValues,
} from "@/components/questionnaire/Questionnaire";
import { GLink } from "@/components/GLink";
import { SoloGazeIcon } from "@/components/icons/SoloGazeIcon";
import { MutualGazeIcon } from "@/components/icons/MutualGazeIcon";
import { RouteMap } from "@/features/RouteMap";
import { Headphones } from "phosphor-react";
import { HeartEye } from "@/components/icons/HeartEye";

type OnboardCarouselOptionProps = {
  tagline?: string;
  icon?: React.ReactElement;
  title: string;
  description: string;
  footer?: (
    values: QuestionnaireValues,
    setValues: React.Dispatch<React.SetStateAction<QuestionnaireValues>>
  ) => JSX.Element;
};

const onboardCarouselOptions: Array<OnboardCarouselOptionProps> = [
  {
    tagline: "Welcome to",
    title: APP_NAME.toUpperCase(),
    description: "Human connection that transcends language",
    footer: () => (
      <Text fontSize="xx-small">
        By pressing start gaze you agree to our{" "}
        <GLink href={RouteMap.privacy.path} color="white">
          Privacy policy
        </GLink>{" "}
        and{" "}
        <GLink href={RouteMap.terms.path} color="white">
          Terms & Conditions
        </GLink>
        .
      </Text>
    ),
  },
  {
    icon: <SoloGazeIcon height="2xs" width="2xs" />,
    title: "Feel unconditional love",
    description:
      "Solo-gaze is a 2 minute meditation exercise that supports connection with yourself.",
  },
  {
    icon: <MutualGazeIcon height="2xs" width="2xs" />,
    title: "Connect without words",
    description:
      "Mutual-gaze is a 2 minute meditation exercise that supports connection with others. Mutual-gaze must be scheduled in advance.",
  },
  {
    icon: <HeartEye height="2xs" />,
    title: "A safe space",
    description:
      "Your gazing partner will only see your eyes. If you have any concerns, tap the screen and leave the session.",
  },

  {
    icon: <Headphones size="10rem" color="black" />,
    title: "An audio experience",
    description: `For the best experience, use ${APP_NAME} with headphones and set your device to do not disturb.`,
  },
];

export const onboardSteps: QuestionnaireStep[] = [
  {
    id: "onboard-1",
    type: "carousel",
    label: "",
    options: onboardCarouselOptions,
    buttonText: "Start Gaze",
    renderOption: (
      option: OnboardCarouselOptionProps,
      values,
      { setValues }
    ) => (
      <VStack
        key={option.title}
        spacing={4}
        height="100%"
        width="100%"
        align="center"
        justify="center"
        textAlign="center"
      >
        <Text as="span">{option.tagline}</Text>

        {option.icon}

        <Heading
          fontSize={option.title === APP_NAME.toUpperCase() ? "6xl" : "3xl"}
          bg="transparent"
          color="black"
        >
          {option.title}
        </Heading>

        <Text maxW="40ch">{option.description}</Text>

        {typeof option.footer === "function"
          ? option.footer(values, setValues)
          : null}
        {React.isValidElement(option.footer) ? option.footer : null}
      </VStack>
    ),
  },
];
