import {
  BoxProps,
  Button,
  ButtonGroup,
  Heading,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { CodeBlock } from "@/components/CodeBlock";
import { Onboarding } from "@/components/Onboarding";
import { QuestionnaireValues } from "@/components/questionnaire/Questionnaire";
import { useControllerActions } from "@/features/controller/useController";
import { RouteMap } from "@/features/RouteMap";
import {
  authActions,
  authApi,
  authSelectors,
} from "@/services/store/slices/AuthStore";
import { useRootDispatch, useRootSelector } from "@/services/store/useStore";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useRouter } from "next/router";
import { useBridgeNotificationSetup } from "../../features/bridge/useBridgeNotificationSetup";
import { BaseLayout, BaseLayoutProps } from "./BaseLayout";

export type AuthenticatedLayoutProps = BaseLayoutProps & {};

const centerStyles: BoxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

/**
 * AuthenticatedLayout
 *
 * The layout for pages requiring basic user authentication.
 * Can be overriden by adding a `getLayout` function to the page.
 */
export function AuthLayout({
  children,

  ...layoutProps
}: AuthenticatedLayoutProps) {
  const router = useRouter();
  const { emit } = useControllerActions();

  useBridgeNotificationSetup();

  const isAuthenticated = useRootSelector(authSelectors.isAuthenticated);
  const dispatch = useRootDispatch();

  const [createSession, { status: authStatus, error: authError, reset }] =
    authApi.useCreateMutation();

  const handleCreateSession = async (values?: QuestionnaireValues) => {
    try {
      await createSession().unwrap();
      await emit("onboardingSubmit", { data: values });
      await router.push(RouteMap.gazeCheckin.path);
    } catch (error) {
      emit("error", { data: error });
    }
  };

  const handleDeleteSession = () => {
    dispatch(authActions.logout());
    reset();
  };

  if (isAuthenticated) {
    return <BaseLayout {...layoutProps}>{children}</BaseLayout>;
  }

  switch (authStatus) {
    case QueryStatus.pending: {
      return (
        <BaseLayout mainProps={centerStyles} title="Authenticating">
          <Heading>Authenticating</Heading>

          <Text>We&apos;re verifying your session. One moment please.</Text>

          <Spinner display="block" mx="auto" />
        </BaseLayout>
      );
    }

    case QueryStatus.rejected: {
      return (
        <BaseLayout mainProps={centerStyles} title="Authentication Error">
          <Heading>Authentication Error</Heading>

          <Text>
            There was an error verifying your session. Please try again.
          </Text>

          <CodeBlock maxWidth="60ch" my="4" textAlign="left">
            {JSON.stringify(authError, null, 2)}
          </CodeBlock>

          <ButtonGroup>
            <Button
              onClick={() => handleCreateSession(undefined)}
              colorScheme="blue"
              variant="outline"
            >
              Try Again
            </Button>

            <Button
              colorScheme="red"
              variant="outline"
              onClick={handleDeleteSession}
            >
              Logout
            </Button>
          </ButtonGroup>
        </BaseLayout>
      );
    }

    case QueryStatus.uninitialized:
    case QueryStatus.fulfilled:
    default: {
      return (
        <BaseLayout title="Onboarding">
          <Onboarding onSubmit={handleCreateSession} />
        </BaseLayout>
      );
    }
  }
}

export default AuthLayout; // <- Required for dynamic import in */pages/_app.tsx
