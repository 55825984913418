import * as React from "react";

import {
  AuthExternalProvider,
  parseExternalProvider,
} from "@/features/auth/ExternalProvider";
import { useControllerActions } from "@/features/controller/useController";
import { authApi } from "@/services/store/slices/AuthStore";
import { useBridge } from "./useBridge";

export function useBridgeNotificationSetup() {
  const { bridge } = useBridge();
  const { emit } = useControllerActions();

  const [createAssociation] = authApi.useCreateAssociationMutation();

  React.useEffect(() => {
    const setupNotificationHandler = () => {
      if (!bridge) return;

      const onError = (_error: unknown) => {
        const error =
          _error instanceof Error ? _error : new Error(JSON.stringify(_error));

        emit("error", { data: error });
      };

      bridge.registerHandler(
        "gotNotificationDeviceToken",
        (deviceToken, responseCallback) => {
          const aep: AuthExternalProvider = {
            provider: "apn",
            external_id:
              typeof deviceToken === "string"
                ? deviceToken
                : JSON.stringify(deviceToken),
          };

          parseExternalProvider(aep)
            .then((externalProvider) =>
              createAssociation(externalProvider)
                .unwrap()
                .then(() => {
                  responseCallback();

                  emit("message", undefined, {
                    toast: {
                      title: "Success",
                      description: "Device token registered.",
                      status: "success",
                    },
                  });
                })
                .catch(onError)
            )
            .catch(onError);
        }
      );
    };

    setupNotificationHandler();
  }, [bridge, createAssociation, emit]);
}
