import { Icon, IconProps } from "@chakra-ui/react";

export function SoloGazeIcon(props: IconProps) {
  const color = typeof props.color === "string" ? props.color : "currentColor";

  return (
    <Icon {...props} viewBox="0 0 375 375">
      <path
        d="M 143.452 160.262 L 156.006 160.262 C 157 160.262 157.806 159.456 157.806 158.462 C 157.806 157.468 157 156.662 156.006 156.662 L 143.452 156.662 C 142.458 156.662 141.652 157.468 141.652 158.462 C 141.652 159.456 142.458 160.262 143.452 160.262 Z M 181.113 156.662 L 168.559 156.662 C 167.565 156.662 166.76 157.468 166.76 158.462 C 166.76 159.456 167.565 160.262 168.559 160.262 L 181.113 160.262 C 182.107 160.262 182.913 159.456 182.913 158.462 C 182.913 157.468 182.107 156.662 181.113 156.662 Z M 206.221 156.662 L 193.667 156.662 C 192.673 156.662 191.867 157.468 191.867 158.462 C 191.867 159.456 192.673 160.262 193.667 160.262 L 206.221 160.262 C 207.215 160.262 208.02 159.456 208.02 158.462 C 208.02 157.468 207.215 156.662 206.221 156.662 Z M 218.774 160.262 L 231.548 160.262 C 232.542 160.262 233.348 159.456 233.348 158.462 C 233.348 157.468 232.542 156.662 231.548 156.662 L 218.774 156.662 C 217.78 156.662 216.974 157.468 216.974 158.462 C 216.974 159.456 217.78 160.262 218.774 160.262 Z M 243.112 156.262 C 242.839 157.218 243.393 158.214 244.349 158.487 L 257.369 162.2 L 246.84 165.398 C 245.889 165.687 245.352 166.693 245.641 167.644 C 245.877 168.421 246.591 168.921 247.363 168.921 C 247.536 168.921 247.713 168.896 247.887 168.843 L 264.263 163.868 C 265.027 163.636 265.547 162.928 265.54 162.13 C 265.533 161.332 265.001 160.634 264.234 160.415 L 245.336 155.025 C 244.38 154.752 243.384 155.306 243.112 156.262 Z"
        fill={color}
      />
      <path
        d="M 101.948 165.332 L 118.324 170.308 C 118.498 170.361 118.675 170.386 118.848 170.386 C 119.62 170.386 120.333 169.885 120.569 169.109 C 120.858 168.158 120.322 167.152 119.37 166.863 L 108.842 163.665 L 121.862 159.951 C 122.818 159.679 123.372 158.683 123.099 157.727 C 122.827 156.771 121.831 156.217 120.875 156.49 L 101.977 161.879 C 101.21 162.098 100.678 162.797 100.671 163.595 C 100.664 164.393 101.184 165.1 101.948 165.332 Z"
        fill={color}
      />
      <path
        d="M 155.076 184.304 L 130.181 155.745 C 129.489 154.95 129.954 152.617 130.186 151.652 C 130.746 149.319 131.851 146.509 132.919 143.792 C 134.643 139.409 136.271 135.269 135.927 132.474 C 134.614 121.798 132.386 112.87 129.118 105.178 C 127.194 100.651 124.875 96.52 122.227 92.901 C 119.334 88.947 115.957 85.478 112.189 82.59 C 111.4 81.985 110.27 82.135 109.666 82.924 C 109.061 83.713 109.21 84.843 109.999 85.447 C 122.269 94.851 129.582 110.377 132.354 132.914 C 132.586 134.802 130.983 138.878 129.569 142.475 C 127.056 148.869 124.681 154.908 127.466 158.109 L 152.421 186.736 C 152.461 186.783 152.504 186.827 152.549 186.869 C 154.96 189.122 155.944 192.386 155.181 195.6 C 154.417 198.814 152.071 201.288 148.902 202.218 L 140.556 204.677 C 139.652 204.944 139.106 205.863 139.306 206.785 L 142.121 219.786 L 135.828 225.29 L 123.563 225.29 C 122.569 225.29 121.763 226.096 121.763 227.09 C 121.763 228.084 122.569 228.89 123.563 228.89 L 135.489 228.89 L 139.006 234.705 L 135.662 241.249 C 135.429 241.703 135.401 242.235 135.583 242.712 L 139.906 253.996 C 141.34 257.722 141.109 261.893 139.272 265.441 C 137.448 268.965 134.203 271.543 130.369 272.514 C 117.765 275.703 102.713 276.704 85.632 275.489 C 85.134 275.454 84.643 275.627 84.277 275.967 C 83.912 276.308 83.704 276.785 83.704 277.285 L 83.704 290.981 L 87.304 290.981 L 87.304 279.208 C 96.118 279.753 104.51 279.703 112.275 279.058 C 119.098 278.491 125.483 277.464 131.253 276.004 C 136.083 274.78 140.172 271.534 142.469 267.096 C 144.779 262.635 145.069 257.389 143.267 252.706 L 139.232 242.172 L 142.667 235.45 C 142.951 234.895 142.927 234.233 142.605 233.699 L 138.826 227.45 L 145.29 221.797 C 145.783 221.366 146.003 220.702 145.864 220.062 L 143.178 207.657 L 149.917 205.671 C 154.34 204.374 157.617 200.92 158.683 196.431 C 159.741 191.975 158.393 187.449 155.076 184.304 Z"
        fill="hsl(0, 0%, 11%)"
        strokeWidth="4.56"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path
        d="M 270.264 82.764 L 270.264 291.138"
        fill="white"
        strokeWidth="6.91"
        stroke={color}
        strokeLinecap="round"
      />
    </Icon>
  );
}
