import { Box } from "@chakra-ui/react";

import {
  Questionnaire,
  QuestionnaireValues,
} from "@/components/questionnaire/Questionnaire";
import { onboardSteps } from "@/components/questionnaire/steps/OnboardSteps";
import { MuteButton } from "@/features/audio/MuteButton";
import { useBackgroundAudio } from "@/features/audio/useBackgroundAudio";

type OnboardingProps = {
  onSubmit: (values: QuestionnaireValues) => void;
};

export function Onboarding({ onSubmit }: OnboardingProps) {
  useBackgroundAudio({ soundId: "background", playmode: "soloAmbient" });

  return (
    <Box height="100%">
      <MuteButton />

      <video
        autoPlay
        loop
        muted
        playsInline
        src={"/video/eyes-16x9.mp4"}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          maxHeight: "75%", // Don't let the video take up the whole screen on desktop
          objectFit: "cover", // Force the video to fill ALL of it's space
          objectPosition: "center",
          zIndex: -1,
        }}
      />

      <Questionnaire
        steps={onboardSteps}
        onSubmit={onSubmit}
        nextProps={{ variant: "primary" }}
        containerProps={{
          pagination: undefined,
          childStyle: { color: "black" },
          containerStyle: {
            bg: "linear-gradient(180deg, transparent 0, #d9c9e3 30%, hsl(269, 50%, 52%) 100%)",
          },
        }}
      />
    </Box>
  );
}
