/** Link an external user to the current session */
export type AuthExternalProvider = {
  /** The name of the provider */
  provider: string;
  /** The user's identifiable variable from the provider*/
  external_id: string;
};

export async function parseExternalProvider(externalProvider: {
  provider?: unknown;
  external_id?: unknown;
}): Promise<AuthExternalProvider> {
  const errors = Object.entries(externalProvider).map(([key, value]) => {
    switch (key) {
      case "provider":
      case "external_id": {
        if (typeof value !== "string") {
          return `${key} must be a string`;
        } else if (value.length <= 2) {
          return `${key} must not be empty`;
        } else if (value.length > 128) {
          return `${key} must be less than 128 characters`;
        }

        return undefined;
      }

      default: {
        return `Unknown external provider key: ${key}`;
      }
    }
  });

  const firstError = errors.find((error) => typeof error === "string");

  if (typeof firstError === "string") {
    throw new Error(firstError);
  }

  return externalProvider as AuthExternalProvider;
}
