import React from "react";
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

export type GLinkProps = NextLinkProps & Omit<ChakraLinkProps, "as">;

/** A link component that uses the Next.js Link component (routing) and the Chakra UI Link component (theming). */
function BaseGLink(
  props: GLinkProps,
  ref?: React.ForwardedRef<HTMLAnchorElement>
) {
  const {
    href,
    as,
    replace,
    scroll,
    shallow,
    prefetch,
    children,
    passHref,
    ...chakraProps
  } = props;

  return (
    <NextLink
      passHref={passHref}
      href={href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
    >
      <ChakraLink textDecoration="underline" ref={ref} {...chakraProps}>
        {children}
      </ChakraLink>
    </NextLink>
  );
}

export const GLink = React.forwardRef<HTMLAnchorElement, GLinkProps>(BaseGLink);
